/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500;700&family=Courier+Prime:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600&family=Red+Hat+Display:wght@300;400;500;700&family=Syne:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Red Hat Display', sans-serif;
}

ul, ul li, p, a, input, input::placeholder, label, textarea, ul, li, ul li, ol, ol li {
  font-family: 'Syne', sans-serif;
}

/* Hide Number input arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}